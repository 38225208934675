import React, { useState } from 'react';
import { Box } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import PropTypes from 'prop-types';
import NavButton from '@/components/Desktop/Gallery/MediaPreview/NavButton';
// import Image from 'next/image';
import { useSelector } from 'react-redux';
import useMaterialMediaQuery from '../use-material-media-query';

const commonArrowStyles = {
  position: 'absolute !important',
  top: 0,
  bottom: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 2,
  pointerEvents: 'none',
};

const previewContainerStyles = {
  width: '100%',
  height: '100%',
  display: 'flex',
  transition: 'transform 0.5s ease-in-out',
};

const previewStyles = {
  minWidth: '100%',
  height: '100%',
  position: 'relative',
  '& * , * > *': {
    width: '100% !important',
    height: '100% !important',
  },
  '& > img': {
    objectFit: 'contain !important',
  },
};

function CustomeImageSlider({
  images,
  height,
  width,
  projectName = '',
  isFirstSearchCard = false,
}) {
  const pageName = useSelector(state => state.headerReducer.pageName);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isNextImage, setisNextImage] = useState(false);
  const [small] = useMaterialMediaQuery();
  const handleNext = () => {
    setisNextImage(true);
    setCurrentIndex(prevIndex => (prevIndex + 1) % images.length);
  };

  const handlePrev = () => {
    setisNextImage(true);
    setCurrentIndex(
      prevIndex => (prevIndex - 1 + images.length) % images.length
    );
  };
  const arrowLeftStyles = {
    ...commonArrowStyles,
    left: small ? 22 : 10,
  };

  const arrowRightStyles = {
    ...commonArrowStyles,
    // right: isSearchMobileCard ? 20 : 10,
    right: small ? 22 : 10,
  };

  const rootStyles = {
    position: 'relative',
    height: 'calc(70% + 10vw)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    borderRadius: '8px',
    '&::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background:
        pageName === 'SearchPage' || pageName === 'Builders_Page'
          ? // background: (pageName === ("SearchPage"|| "Builders_Page")) && !small ?
          'linear-gradient(0deg, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.4) 11%, rgba(0,0,0,0) 23%, rgba(0,0,0,0) 42%, rgba(0,0,0,0) 62%, rgba(0,0,0,0.3) 81%, rgba(0,0,0,0.6) 100%)'
          : 'linear-gradient(0deg, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0.4) 21%, rgba(0,0,0,0) 37%)',
      zIndex: 1,
    },
  };

  return (
    <Box sx={{ ...rootStyles, maxHeight: height, maxWidth: width }}>
      {/* Left Navigation Arrow Button */}
      {currentIndex !== 0 && (
        <Box sx={arrowLeftStyles}>
          <NavButton
            aria-label="previous button"
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              handlePrev(e);
            }}
            disableRipple>
            <ChevronLeftIcon fontSize="medium" />
          </NavButton>
        </Box>
      )}
      {/* Right Navigation Arrow Button */}
      {currentIndex + 1 < images?.length && (
        <Box sx={arrowRightStyles}>
          <NavButton
            aria-label="next button"
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              handleNext(e);
            }}
            disableRipple>
            <ChevronRightIcon fontSize="medium" />
          </NavButton>
        </Box>
      )}
      <Box
        sx={previewContainerStyles}
        style={{ transform: `translateX(-${currentIndex * 100}%)` }}>
        {isNextImage ? (
          images.map((src, index) => (
            <img
              key={index}
              style={previewStyles}
              alt={projectName}
              height={height}
              width={width}
              src={`${src}?tr=w-${width},h-${height}`}
              fetchpriority={index === 0 && isFirstSearchCard ? 'high' : 'low'}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = `https://ik.imagekit.io/osmz1lxs6d0/propertygallery/noimage.webp?tr=w-${width},h-${height}`;
              }}
            />
            // <Image
            //   key={index}
            //   style={previewStyles}
            //   loader={imageLoader}
            //   alt={projectName}
            //   height={height}
            //   width={width}
            //   src={`${src}?tr=w-${width},h-${height}`}
            //   unoptimized={true}
            //   fetchpriority={index === 0 && isFirstSearchCard ? 'high' : 'low'}
            //   // blurDataURL={`${src}?tr=w-${width},h-${height}`}
            //   onError={({ currentTarget }) => {
            //     currentTarget.onerror = null;
            //     currentTarget.src = `https://ik.imagekit.io/osmz1lxs6d0/propertygallery/noimage.webp?tr=w-${width},h-${height}`;
            //   }}
            // />
          ))
        ) : (
          <img
            style={previewStyles}
            alt={projectName}
            height={height}
            width={width}
            src={`${images[0]}?tr=w-${width},h-${height}`}
              fetchpriority={isFirstSearchCard ? 'high' : 'low'}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = `https://ik.imagekit.io/osmz1lxs6d0/propertygallery/noimage.webp?tr=w-${width},h-${height}`;
            }}
          />
        )}
      </Box>
    </Box>
  );
}

CustomeImageSlider.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  projectName: PropTypes.string,
};

// function imageLoader({ src, width, height }) {
//   return `${src}?tr=w-${width},h-${height}`;
// }

export default CustomeImageSlider;
