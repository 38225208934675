import NextHead from 'next/head';
import { AHREFS_META_ENABLED, DOMAIN_NAME } from '../../config';
import homePageSEOschema from '../../data/homePageSEOschema.json';
import { slugify } from '../util';
// import useMaterialMediaQuery from '../packages/use-material-media-query';

const SEOHeaderComponent = (props) => {
  const {
    meta_data,
    full_url_slug,
    willBuildSchema = true,
    noIndex = false,
    pageName = null,
    projectImage = null,
  } = props;
  const description =
    'Explore Verified Residential Properties in India. Find New Real Estate Projects & Bestselling Properties in India Only at HomeBazaar.com. Get A Free Quote Today.';
  const title = 'Property for sale';
  const keywords =
    'property in india, properties in india, new projects in india, real estate in india, real estate projects in india, residential property in india, india property';
  const image = `${DOMAIN_NAME}static/images/homebazaarlogo.webp`;

  const renderSchemaScripts = (schemaData) => (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{ __html: JSON.stringify(schemaData) }}
    />
  );

  // const [small, large] = useMaterialMediaQuery();
  // const renderOrganizationSchema = (meta_data, willBuildSchema) => (
  //   willBuildSchema &&
  //   meta_data?.pageType && (
  //     <>
  //       {[
  //         'Buy Global Search Page',
  //         'Rent Global Search Page',
  //         'Buy Location Search Page',
  //         'Buy Location Search Search Page',
  //         'Rent Location Search Page',
  //         'Buy Commercial Location Search Page',
  //         'Rent Commercial Location Search Page',
  //         'Buy Location Config Page',
  //         'Rent Location Config Page',
  //         'Buy Location Under Construction Page',
  //         'Rent Location Under Construction Page',
  //         'Buy Location Price Range',
  //         'Rent Location Price Range',
  //       ].some((pageType) => meta_data?.pageType.includes(pageType)) &&
  //         renderSchemaScripts(homePageSEOschema.OrganizationSchema)}
  //     </>
  //   )
  // );

  return (
  
    <>
    
    <NextHead>
      <title>{meta_data?.title || title}</title>
      {noIndex ? (
        <meta name="robots" content="noindex" />
      ) : (
        <meta
          name="robots"
          content="follow, index, max-snippet:-1, max-video-preview:-1, max-image-preview:large"
        />
      )}
      <meta
        name="description"
        content={meta_data?.description || description}
      />
      <meta property="og:title" content={meta_data?.title || title} />
      <meta property="og:type" content="website" />
      <meta property="og:image" content={projectImage ?? image} />
      <meta
        property="og:description"
        content={meta_data?.description || description}
      />
      <meta property="og:url" content={full_url_slug} />
      <meta property="og:site_name" content="HomeBazaar.com" />
      <meta name="twitter:url" content={full_url_slug} />
      <meta name="twitter:title" content={meta_data?.title || title} />
      <meta name="twitter:image:src" content={image} />
      <meta
        name="twitter:description"
        content={meta_data?.description || description}
      />
      <meta name="keywords" content={meta_data?.keywords || keywords} />
      <meta name="author" content="HomeBazaar.com" />
      <meta
        name="google-site-verification"
        content="6nvaafkv_DQSGOOG9OO51S4mDj3Aox_d3aDEOxr8fLM"
      />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, shrink-to-fit=no"
      />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, maximum-scale=5.0"
      />

      {AHREFS_META_ENABLED && (
        <meta
          name="ahrefs-site-verification"
          content="39a36a5f720a94e55e7b70de92806ecbdeb9979152673d848ee10e850bcd853a"
        />
      )}
      <link rel="canonical" href={(full_url_slug ?? '').toLowerCase()} />

    

      {/* Search Page Schema */}
      {/* {renderOrganizationSchema(meta_data, willBuildSchema)} */}
    </NextHead>

      {/* Developer Schema */}
      {meta_data?.developerDetails && willBuildSchema && (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: `{"@context":"http://schema.org/","@type":"Product","name":"${meta_data?.projectName}","image":"${image}","description":"${meta_data?.projectDescription ?? meta_data?.description
              }","offers":{"@type":"AggregateOffer","priceCurrency":"INR","lowPrice":${meta_data?.minPrice
              },"highPrice":${meta_data?.maxPrice
              },"offerCount":"3014","seller":[{"@type":"Organization","name":"Homebazaar.com"}]},"brand":{"@type":"Organization","name":"${meta_data?.developerDetails?.developerName
              }","url":"https://www.homebazaar.com/buy/builders/${slugify(
                meta_data?.developerDetails?.developerName
              )}","description":"${meta_data?.developerDetails?.developerDescription
              }"}}`,
          }}
        />
      )}

      {/* Home Page Schema */}
      {pageName == 'Home_Page' && willBuildSchema && (
        <>
          {renderSchemaScripts(homePageSEOschema.BusinessSchema)}
          {renderSchemaScripts(homePageSEOschema.OrganizationSchema)}
        </>
      )}

      {/* Developer Schema */}
      {meta_data?.developerDetails && willBuildSchema && (
        <>
          {renderSchemaScripts({
            "@context": "http://schema.org/",
            "@type": "Residence",
            address: {
              "@type": "PostalAddress",
              addressLocality: meta_data?.locality,
              addressRegion: meta_data?.popularPart,
            },
          })}
          {renderSchemaScripts({
            "@context": "http://schema.org/",
            "@type": "Place",
            geo: {
              "@type": "GeoCoordinates",
              latitude: meta_data?.latitude,
              longitude: meta_data?.longitude,
            },
          })}
        </>
      )}
    </>
  );
};

export default SEOHeaderComponent;
